<template>
  <div class="w-auto h-auto">
    <div>
    <div class="spin w-full flex justify-center">
<!--      <scale-loader-->
<!--        color="#006FB1"-->
<!--        class="text-center"-->
<!--      />-->
      <SpinnerLoader :color="color" />
    </div>
    </div>
  </div>
</template>

<script>
// import ScaleLoader from 'vue-spinner/src/FadeLoader'

export default {
  name: 'index',
  components: {
    // ScaleLoader
  },
  props: {
    color: {
      type: String,
      default: '#006FB1'
    }
  },
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";
.spin{
  width: 100%;
}
</style>
